import moment from 'moment';
import { useEffect, useState } from 'react';

/**
 *
 * @param message String
 * @param expiryDateTime String: YYYY-MM-DD HH:mm:ss
 * @returns
 */
const RedAlert = ({
  message,
  expiryDateTime,
}: {
  message: string;
  expiryDateTime: string;
}) => {
  const [showAlert, setAlert] = useState(false);

  useEffect(() => {
    if (isAfterSpecifiedDateTime(expiryDateTime)) setAlert(false);
    else setAlert(!!expiryDateTime);
  }, []);

  function isAfterSpecifiedDateTime(specifiedDateTime: any) {
    return moment(moment().format('YYYY-MM-DD HH:mm:ss')).isAfter(
      specifiedDateTime
    );
  }

  if (!showAlert) return <></>;

  return (
    <div className='flex w-full items-center justify-center bg-[red] text-sm text-white'>
      <div className='flex-1 p-2 text-center'>{message}</div>
    </div>
  );
};

export default RedAlert;
